import React from "react";
import { Route, BrowserRouter as Router, Routes, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import CsvUploadPage from "./CsvUploadPage";
import HomePage from "./HomePage";

function App() {
  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Outils de gestion du pole finance
          </Typography>
          <Button color="inherit" component={Link} to="/">
            Accueil
          </Button>
          <Button color="inherit" component={Link} to="/reconcilier-le-cas">
            RECONCILIER LE CAS
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ mt: 2 }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/reconcilier-le-cas" element={<CsvUploadPage />} />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;