import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Button, Checkbox, CircularProgress, Typography, Paper, Collapse, Container } from '@mui/material';
import ResultSection from './components/ResultSection'; // Import du composant

const API_ENDPOINT = '/upload-csv';

const ExpandCollapse = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Button variant="outlined" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Masquer' : 'Afficher'} {title}
      </Button>
      <Collapse in={isOpen}>
        <Paper elevation={2} sx={{ mt: 2, p: 4, backgroundColor: '#f9f9f9' }}>
          <Typography variant="body2" component="pre">
            {children}
          </Typography>
        </Paper>
      </Collapse>
    </div>
  );
};

const CsvUploadPage = () => {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [sendToZoho, setSendToZoho] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
    multiple: false,
  });

  const handleSubmit = async () => {
    if (!file) {
      setError('Veuillez sélectionner un fichier CSV.');
      return;
    }

    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('csvFile', file);
    formData.append('sendToZoho', JSON.stringify(sendToZoho));

    try {
      const response = await axios.post(API_ENDPOINT, formData);
      setResult(response.data);
    } catch (err) {
      setError(`Erreur lors de l'envoi du fichier: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Typography variant="h4" gutterBottom align="center">
        Télécharger le fichier CSV
      </Typography>
      
      <Paper {...getRootProps()} variant="outlined" sx={{ p: 4, mb: 4, textAlign: 'center', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body1">Déposez le fichier ici ...</Typography>
        ) : (
          <Typography variant="body1">Glissez et déposez un fichier CSV ici, ou cliquez pour sélectionner un fichier</Typography>
        )}
      </Paper>
      
      {file && <Typography sx={{ mb: 4 }}>Fichier sélectionné: {file.name}</Typography>}
      
      <div style={{ marginBottom: '1rem' }}>
        <Checkbox
          checked={sendToZoho}
          onChange={(e) => setSendToZoho(e.target.checked)}
          inputProps={{ 'aria-label': 'Activer envoi Zoho' }}
        />
        <label>Activer envoi Zoho</label>
      </div>
      
      <div style={{ textAlign: 'center' }}>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isLoading || !file}>
          {isLoading ? <CircularProgress size={24} /> : 'Envoyer le fichier CSV'}
        </Button>
      </div>
      
      {error && <Typography color="error" align="center" sx={{ mt: 4 }}>{error}</Typography>}
      
      {result && (
        <div className="mt-8">
          <Typography variant="h5" gutterBottom align="center">Résultat :</Typography>
          
          <ExpandCollapse title="JSON Complet">
            {JSON.stringify(result.zohoJson, null, 2)}
          </ExpandCollapse>
          
          <ResultSection
            title="VIREMENT(S) RECU(S) DE L'ANAH POUR DOSSIERS Cédés :"
            content={result.mprResults}
          />
          <ResultSection
            title="VIREMENT(S) RECU(S) DE L'ANAH POUR DOSSIERS NON Cédés :"
            content={result.mprNotSienna}
          />
          <ResultSection
            title="VIREMENT(S) RECU(S) DE L'ANAH POUR DOSSIERS INCONNUS :"
            content={result.mprNotFound}
          />
          <ResultSection
            title="VIREMENT(S) RECU(S) DE(S) DELEGATAIRE(S) CEE :"
            content={result.ceeResults}
          />
          <ResultSection
            title="LIGNES NON COMPRISES PAR LE ROBOT :"
            content={result.unknownLines}
          />
        </div>
      )}
    </Container>
  );
};

export default CsvUploadPage;