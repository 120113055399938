import React from 'react';
import { Paper, Typography } from '@mui/material';

const ResultSection = ({ title, content }) => (
  <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    <Typography variant="body2" component="pre" sx={{ backgroundColor: '#f5f5f5', p: 2, borderRadius: 1 }}>
      {content?.length ? content.join('\n') : 'RAS'}
    </Typography>
  </Paper>
);

export default ResultSection;