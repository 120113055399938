import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Checkbox,
  CircularProgress,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import ResultSection from "./components/ResultSection"; // Import du composant

const HomePage = () => {
  const [filesInfo, setFilesInfo] = useState([]);
  const [fileOne, setFileOne] = useState(null);
  const [fileTwo, setFileTwo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sendToZoho, setSendToZoho] = useState(true);

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const onDropFileOne = (acceptedFiles) => {
    setFileOne(acceptedFiles[0]);
  };

  const onDropFileTwo = (acceptedFiles) => {
    setFileTwo(acceptedFiles[0]);
  };

  const { getRootProps: getRootPropsOne, getInputProps: getInputPropsOne } =
    useDropzone({ onDrop: onDropFileOne });
  const { getRootProps: getRootPropsTwo, getInputProps: getInputPropsTwo } =
    useDropzone({ onDrop: onDropFileTwo });

  const handleSubmit = () => {
    const formData = new FormData();
    setIsLoading(true);

    if (fileOne) formData.append("fileOne", fileOne);
    if (fileTwo) formData.append("fileTwo", fileTwo);
    formData.append("sendToZoho", JSON.stringify(sendToZoho));

    fetch("/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (Array.isArray(data)) {
          setFilesInfo(data);
        } else {
          console.error("La réponse n'est pas un tableau", data);
        }
      })
      .catch((error) => {
        console.error("Erreur:", error);
        setIsLoading(false);
      });
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Card {...getRootPropsOne()} sx={{ cursor: "pointer" }}>
            <CardContent>
              <Typography variant="h6" textAlign="center">
                Fichier Hier
              </Typography>
              <Box
                sx={{
                  border: "2px dashed #007bff",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <input {...getInputPropsOne()} />
                {fileOne ? (
                  <Typography>{fileOne.name}</Typography>
                ) : (
                  <Typography>Uploader le fichier 1</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card {...getRootPropsTwo()} sx={{ cursor: "pointer" }}>
            <CardContent>
              <Typography variant="h6" textAlign="center">
                Fichier Aujourd'hui
              </Typography>
              <Box
                sx={{
                  border: "2px dashed #007bff",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <input {...getInputPropsTwo()} />
                {fileTwo ? (
                  <Typography>{fileTwo.name}</Typography>
                ) : (
                  <Typography>Uploader le fichier 2</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Checkbox
          checked={sendToZoho}
          onChange={(e) => setSendToZoho(e.target.checked)}
          inputProps={{ "aria-label": "Activer envoi Zoho" }}
        />
        <label>Activer envoi Zoho</label>

        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ mt: 2, height: "50px" }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            "Envoyer les fichiers au serveur"
          )}
        </Button>
      </Box>

      {filesInfo.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom align="center">
            Résultat :
          </Typography>

          <ResultSection
            title="Fichiers traités :"
            content={filesInfo}
          />
        </Box>
      )}

      {isLoading && (
        <Box sx={overlayStyle}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default HomePage;